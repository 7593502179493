var axios = require('axios');

export default class MainService {

    constructor() {
        var token = JSON.parse(localStorage.getItem('token'));
        axios.defaults.baseURL = process.env.VUE_APP_MAIN_SERVICE;
        if (token) {
            axios.defaults.headers.common = {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }else{
            axios.defaults.headers.common = {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    }

    getToken() {
        return axios.defaults.headers.common;
    }

    login(item) {
        return axios.post('api/login', item);
    }

    uploadFile(item) {
        return axios.post('api/uploadFile', item);
    }

    uploadFile2(item) {
        return axios.post('api/uploadFile2', item);
    }

    loginToken365(item) {
        return axios.post('api/loginToken365', item);
    }

    indexPersona() {
        return axios.defaults.baseURL + 'api/Persona/index';
    }

    showPersona(id) {
        return axios.get('api/Persona/show?id=' + id);
    }

    listPersona() {
        return axios.get('api/Persona/list');
    }

    storePersona(item) {
        return axios.post('api/Persona/store', item);
    }

    destroyPersona(item) {
        return axios.post('api/Persona/destroy', item);
    }

    changePassword(item) {
        return axios.post('api/Persona/changePassword', item);
    }

    showUnidadAcademica(id) {
        return axios.get('api/UnidadAcademica/show?id=' + id);
    }

    indexUnidadAcademica() {
        return axios.defaults.baseURL + 'api/UnidadAcademica/index';
    }

    listUnidadAcademica() {
        return axios.get('api/UnidadAcademica/list');
    }

    storeUnidadAcademica(item) {
        return axios.post('api/UnidadAcademica/store', item);
    }

    destroyUnidadAcademica(item) {
        return axios.post('api/UnidadAcademica/destroy', item);
    }

    showRol(id) {
        return axios.get('api/Rol/show?id=' + id);
    }

    indexRol() {
        return axios.defaults.baseURL + 'api/Rol/index';
    }

    listRol() {
        return axios.get('api/Rol/list');
    }

    storeRol(item) {
        return axios.post('api/Rol/store', item);
    }

    destroyRol(item) {
        return axios.post('api/Rol/destroy', item);
    }
  

    //TipoReporte
    showTipoReporte(id) {
        return axios.get('api/TipoReporte/show?id=' + id);
    }

    indexTipoReporte() {
        return axios.defaults.baseURL + 'api/TipoReporte/index';
    }

    listTipoReporte() {
        return axios.get('api/TipoReporte/list');
    }

    storeTipoReporte(item) {
        return axios.post('api/TipoReporte/store', item);
    }

    destroyTipoReporte(item) {
        return axios.post('api/TipoReporte/destroy', item);
    }

    generateTipoReporte(item) {
        return axios.post('api/TipoReporte/generate', item);
    }

    //Pensums
    indexPensums() {
        return axios.defaults.baseURL + 'api/Pensums/index';
    }
    listPensums() {
        return axios.get('api/Pensums/list');
    }
    getPensums(item){
        return axios.get('api/Pensums/index', {
            params: {
                Materia: item.Materia,
                Sigla: item.Sigla,
                NivelAcad: item.NivelAcad,
                Especialidad: item.Especialidad,
                Gestion: item.Gestion
                // Agrega más parámetros si es necesario
            }
        });
    }

    //Materia
    indexMateria() {
        return axios.defaults.baseURL + 'api/Materia/index';
    }
    listMateria(id) {
        return axios.get('api/Materia/list?idEspecialidad=' + id);
    }
    showMateria(id) {
        return axios.get('api/Materia/show?id=' + id);
    }
    showPensum(id) {
        return axios.get('api/Materia/showPensum?id=' + id);
    }
    storeMateria(item) {
        return axios.post('api/Materia/store', item);
    }
    pdfMateria() {
        return axios.get('api/Materia/pdf');
    }

    //MateriaCompetencia
    showMateriaCompetencia(idMateria) {
        return axios.get('api/MateriaCompetencia/show?idMateria=' + idMateria);
    }
    storeMateriaCompetencia(item) {
        return axios.post('api/MateriaCompetencia/store', item);
    }

    //Criterios de desempeño
    listMateriaCriterios(idMateria) {
        return axios.get('api/MateriaCriterios/list?idMateria='+idMateria);
    }

    storeMateriaCriterios(item) {
        return axios.post('api/MateriaCriterios/store',item);
    }

    destroyMateriaCriterios(item) {
        return axios.post('api/MateriaCriterios/destroy', item);
    }

    //Unidad Didactica
    listUnidadDidactica(id) {
        return axios.get('api/UnidadDidactica/list?MateriaCriterioDesempeno_id='+id);
    }

    storeUnidadDidactica(item) {
        return axios.post('api/UnidadDidactica/store',item);
    }

    destroyUnidadDidactica(item) {
        return axios.post('api/UnidadDidactica/destroy', item);
    }

    //Contenido Analitico
    listContenidoAnalitico(id) {
        return axios.get('api/ContenidoAnalitico/list?MateriaContenido_id='+id);
    }

    storeContenidoAnalitico(item) {
        return axios.post('api/ContenidoAnalitico/store',item);
    }

    destroyContenidoAnalitico(item) {
        return axios.post('api/ContenidoAnalitico/destroy', item);
    }

    //Especialidad
    indexEspecialidades() {
        return axios.defaults.baseURL + 'api/Especialidad/index';
    }
    listEspecialidad() {
        return axios.get('api/Especialidad/list');
    }
    showEspecialidad(id) {
        return axios.get('api/Especialidad/show?idEspecialidad='+id);
    }
    storeEspecialidad(item) {
        return axios.post('api/Especialidad/store', item);
    }

    //Competencia General
    showCompetenciaGeneral(id) {
        return axios.get('api/CompetenciaGeneral/show?idEspecialidad='+id);
    }
    storeCompetenciaGeneral(item) {
        return axios.post('api/CompetenciaGeneral/store', item);
    }

    //Competencia Area
    showCompetenciaArea(id) {
        return axios.get('api/CompetenciaArea/show?id='+id);
    }
    storeCompetenciaArea(item) {
        return axios.post('api/CompetenciaArea/store',item);
    }
    destroyCompetenciaArea(item) {
        return axios.post('api/CompetenciaArea/destroy',item);
    }

    //Competencia Especifica
    showCompetenciaEspecifica(id) {
        return axios.get('api/CompetenciaEspecifica/show?id='+id);
    }
    storeCompetenciaEspecifica(item) {
        return axios.post('api/CompetenciaEspecifica/store',item);
    }
    listCompetenciaEspecifica(id) {
        return axios.get('api/CompetenciaEspecifica/list?id='+id);
    }
    destroyCompetenciaEspecifica(item) {
        return axios.post('api/CompetenciaEspecifica/destroy',item);
    }

    //Competencia Instrumentales
    showCompetenciaInstrumentales(id) {
        return axios.get('api/CompetenciaInstrumetales/show?id='+id);
    }
    storeCompetenciaInstrumentales(item) {
        return axios.post('api/CompetenciaInstrumetales/store',item);
    }
    destroyCompetenciaInstrumentales(item) {
        return axios.post('api/CompetenciaInstrumetales/destroy',item);
    }
    
    //Periodo Academico
    listPeriodosAcademicos(id) {
     return axios.get('api/Periodo/list?id='+id);
    }

    //Materia Carga Horaria
    showMateriaCargaHoraria(item) {
        return axios.post('api/MateriaCargaHoraria/show?',item);
    }
    storeMateriaCargaHoraria(item) {
        return axios.post('api/MateriaCargaHoraria/store',item);
    }

    //Materia Bibliografia
    listBibliografia(id) {
        return axios.get('api/Bibliografia/list?id='+id);
    }
    storeBibliografia(item) {
        return axios.post('api/Bibliografia/store', item);
    }
    destroyBibliografia(item) {
        return axios.post('api/Bibliografia/destroy', item);
    }

    //Materia Requisitos
    listRequisitos(id) {
        return axios.get('api/Requisitos/list?id='+id);
    }
    storeRequisitos(item) {
        return axios.post('api/Requisitos/store',item);
    }
    destroyRequisitos(item) {
        return axios.post('api/Requisitos/destroy',item);
    }
    generarMateriaRequisito(id){
        return axios.get('api/Requisitos/reporteMateriasRequisitos?id=', id);
    }


    //Validacion
    indexValidacion() {
        return axios.defaults.baseURL + 'api/Validacion/index';
    }

    listObservaciones(item){
        return axios.post('api/Validacion/listObservaciones', item);
    }

    listObservacionDetalle(item){
        return axios.post('api/Validacion/listObservacionesDetalle', item);
    }

    storeObservacion(item){
        return axios.post('api/Validacion/storeObservacion', item);
    }

    storeObservacionDetalle(item){
        return axios.post('api/Validacion/storeObservacionDetalle', item);
    }

    destroyObservacionDetalle(item){
        return axios.post('api/Validacion/destroyObservacionDetalle', item);
    }

    destroyObservacion(item){
        return axios.post('api/Validacion/destroyObservacion', item);
    }

    generarFormulario(id){
        return axios.get('api/Validacion/ImprimirFormularioObservacion?id=', id);
    }

    showValidacion(item){
        return axios.post('api/Validacion/showValidacion',  item);
    }

    storeValidacion(item){
        return axios.post('api/Validacion/storeValidacion',  item);
    }

    //Archivo capitulo
    indexArchivoCapitulo() {
        return axios.defaults.baseURL + 'api/ArchivoCapitulo/index';
    }
    indexArchivoCapitulo2() {
        return axios.defaults.baseURL + 'api/ArchivoCapitulo/index2';
    }
    indexArchivoCapitulo3() {
        return axios.defaults.baseURL + 'api/ArchivoCapitulo/index3';
    }
    indexArchivoCapitulo4() {
        return axios.defaults.baseURL + 'api/ArchivoCapitulo/index4';
    }
    indexArchivoCapitulo5() {
        return axios.defaults.baseURL + 'api/ArchivoCapitulo/index5';
    }
    indexArchivoCapitulo6() {
        return axios.defaults.baseURL + 'api/ArchivoCapitulo/index6';
    }
    indexArchivoCapitulo7() {
        return axios.defaults.baseURL + 'api/ArchivoCapitulo/index7';
    }
    indexArchivoCapitulo8() {
        return axios.defaults.baseURL + 'api/ArchivoCapitulo/index8';
    }
    indexArchivoCapitulo9() {
        return axios.defaults.baseURL + 'api/ArchivoCapitulo/index9';
    }
    indexArchivoCapitulo11() {
        return axios.defaults.baseURL + 'api/ArchivoCapitulo/index11';
    }
    indexArchivoCapitulo12() {
        return axios.defaults.baseURL + 'api/ArchivoCapitulo/index12';
    }
    indexArchivoCapitulo13() {
        return axios.defaults.baseURL + 'api/ArchivoCapitulo/index13';
    }
    indexArchivoCapitulo10() {
        return axios.defaults.baseURL + 'api/ArchivoCapitulo/index10';
    }
    showarchivo(idEspecialidad) {
        return axios.get('api/ArchivoCapitulo/show?idEspecialidad='+idEspecialidad);
    }
    storearchivo(item) {
        console.log({item})
        return axios.post('api/ArchivoCapitulo/store',item);
    }
    storearchivo2(item) {
        console.log({item})

        return axios.post('api/ArchivoCapitulo/store',item);
    }
    showarchivo2(idEspecialidad) {
        return axios.get('api/ArchivoCapitulo/show?idEspecialidad='+idEspecialidad);
    }
    /*unirPdf ( idEspecialidad ) {
        
        return axios.get('api/ArchivoCapitulo/unirpdf?idEspecialidad='+idEspecialidad);
    }*/

    unirPdf(idEspecialidad) {
        return axios.get('api/ArchivoCapitulo/unirpdf?idEspecialidad='+idEspecialidad, {
            
            responseType: 'blob' // Importante para manejar archivos binarios
        });
    }
}