import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "PensumsPage",
    data() {
        let ss = new MainService();
        return {
            msg: "PensumsPage",
            ss: ss,
            ajax: {
                "url": ss.indexMateria(),
                headers: ss.getToken(),
                data: function (d) {
                    d.idEspecialidad = 0;
                }
            },
            columns: [
                { data: 'idMateria', name: 'idMateria', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Materia', name: 'Materia', title: 'Materia' },
                { data: 'Sigla', name: 'Sigla', title: 'Sigla' },
                { data: 'TipoMateria', name: 'TipoMateria', title: 'Tipo Materia' },
                { data: 'tipo', name: 'tipo', title: 'tipo' },
                //{ data: 'Periodo', name: 'Periodo', title: 'Periodo' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            seccionValue: 0,
            onclickCompetencia: 0,
            onclickCriterios: 0,
            onclickContenidos: 0,
            pensums: [],
            materias: [],
            materiaRequisitos: [],
            bibliografias: [],
            unidadAcademicas: [],
            materiaCriterios: [],
            contenidoAnalitco: [],
            competenciasInstrumentales:[],
            competenciasEspecificas:[],
            especialidadsaga:{},
            especialidadesSAGA:[],
            tipoMateriaRequisito:[
                {value:'SUB-SECUENTE'},
                {value:'PRECEDENTE'}
            ],
            unidadesDidacticas: {},
            bibliografia: {},
            materiaCompetenciaSelect: {},
            competenciaInstrumental:{},
            buscarSaga: {},
            competenciaGeneral:{},
            contenidoA: {},
            criterio:{},
            criterioSeleccionado:{},
            unidadDidactica:{},
            pensumShow:{},
            rols: [],
            persona: {},
            materia: {},
            materia2: {},
            materiaPropuesta: {},
            materiaCompetencia: {},
            materiaRequisito: {},
            justificacion: {},
            competencia: {},
            isLoading: false,
            isLoadingFile: false,
            isPropuesta: false,
            errorBag: {},
            password: {}
        };
    },
    methods: {
        loadFile(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.persona.Foto = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },
        getPensums() {
            this.ss.listPensums().then(
                (result) => {
                    let response = result.data;
                    let data = response.data;
                    this.pensums = data;
                    console.log(this.pensums);
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getCompetenciasEspecificas(id) {
            this.ss.listCompetenciaEspecifica(id).then(
                (result) => {
                    let response = result.data;
                    this.competenciasEspecificas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        async getEspecialidad2(){
            this.isLoading=true;
        if(this.especialidadsaga.idEspecialidad){
            let id = this.especialidadsaga.idEspecialidad;
            this.ajax.data = function(d){
                d.idEspecialidad = id;
            }
        }
        else{
            let id = this.persona.idEspecialidad;
            this.ajax.data = function(d){
                d.idEspecialidad = id;
            }
        }
        await new Promise(resolve => setTimeout(resolve, 2000));
    
        this.$refs['datatable-pensums'].reloadAjax(this.ajax);
        this.isLoading=false;
        }, 

        cancelshow(){
            this.$refs['view-pensum'].hide();
        },

        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadAcademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getMateriaRequisito(id) {
            this.ss.listRequisitos(id).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                    if(response.success){
                        this.materiaRequisitos = response.data;
                    }else{
                        this.materiaRequisitos = [];
                        console.log(this.materiaRequisitos);
                    }
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getRol() {
            this.ss.listRol().then(
                (result) => {
                    let response = result.data;
                    this.rols = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        storeJustificacion() {
            this.justificacion = {};
            this.justificacion.Justificacion = this.materia.Justificacion;
            this.$refs['frm-justificacion'].show();
        },

        storeCriteriosDesempeño(item) {
            if(item){
                this.criterio = item;
            }
            else{
                this.criterio = {};
            }
            this.$refs['frm-criterio-desempenio'].show();
        },

        storeUnidadDidactica(item) {
            if(item){
                this.unidadDidactica = item;
            }
            else{
                this.unidadDidactica = {};
            }
            this.$refs['frm-unidad-didactica'].show();
        },

        storeContenidoAnalitico(item) {
            if(item){
                this.contenidoA = item;
            }
            else{
                this.contenidoA = {};
            }
            this.$refs['frm-contenido-analitico'].show();
        },

        storeBibliografia(item){
            if(item){
                this.bibliografia = item;
            }else{
                this.bibliografia = {};
            }

            this.$refs['frm-materia-bibliografia'].show();

        },

        savebibliografia(){
            this.bibliografia.idMateria = this.materia.idMateria;
            console.log(this.bibliografia);
            this.ss.storeBibliografia(this.bibliografia).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    if(response.acc == 'creado'){
                        this.listBibliografia(this.materia.idMateria);
                    }
                    this.$refs['frm-materia-bibliografia'].hide();
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        listBibliografia(id){
            this.ss.listBibliografia(id).then(
                (result) => {
                    let response = result.data;
                    if(response.data){
                        this.bibliografias = response.data;
                    }else{
                        this.bibliografias = [];
                    }
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        cancelStoreBibliografia(){
            this.bibliografia = {};
            this.$refs['frm-materia-bibliografia'].hide();
        },

        storeMateriaCompetencia() {
            this.competencia = {};
            if(this.materiaCompetencia){
                this.competencia.CodigoCompetencia = this.materiaCompetencia.CodigoCompetencia;
                this.competencia.DescripcionCompetencia = this.materiaCompetencia.DescripcionCompetencia;
            }
            else{
                this.competencia.CodigoCompetencia = "";
                this.competencia.DescripcionCompetencia = "";
            }
            
            this.$refs['frm-materia-competencia'].show();
        },


        showPensumMateria(id){
            console.log("ID: "+id);
            this.materia={};
            this.materia2={};
            this.ss.showPensum(id).then(
                (result) => {
                    let response = result.data;
                    this.materia = response.data;
                    //this.materia2 = this.materia;
                    console.log("Metodo correcto");
                    console.log(this.materia.idMateria);
                    this.showMateria(this.materia.idMateria);
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        showMateria(id) {
            //this.materia={};
            this.ss.showMateria(id).then(
                (result) => {
                    let response = result.data;
                    this.materia2 = response.data;
                    //this.materia2 = this.materia;
                    console.log(this.materia2);
                    console.log("modificacion");
                    //console.log(this.materia2);
                    this.showMateriaCompetencia(this.materia.idMateria);
                    this.showCompetenciaGeneral(this.materia.idEspecialidad);
                    this.listBibliografia(this.materia.idMateria);
                    this.getMateriaRequisito(this.materia.idMateria);
                    this.showPensum();
                    //this.materia = response.data; 
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        generateMateriaRequisito(){
            console.log(this.especialidadsaga.idEspecialidad);
            let url = `${process.env.VUE_APP_MAIN_SERVICE}api/Requisitos/reporteMateriasRequisitos?id=${this.especialidadsaga.idEspecialidad}`;
            console.log(url);
            window.open(url, '_blank');
        },

        destroyBibliografia(id){
            this.ss.destroyBibliografia(id).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                    this.listBibliografia(this.materia.idMateria);
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });

        },

        listMaterias(id){
            this.materias=[];
            this.ss.listMateria(id).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                    if(response.data){
                        this.materias = response.data;
                        console.log("funcion correcta");
                        console.log(this.materias);
                    }
                    else{
                        console.log("fallo en el id");
                        this.materias=[];
                    }
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        storeMateriaRequisito(){
            this.materiaRequisito.idMateria = this.materia.idMateria;
            if(this.persona.idEspecialidad){
                console.log("esta es la funcion 2");
                this.listMaterias(this.persona.idEspecialidad);
            }
            else{
                this.listMaterias(this.materia.idEspecialidad);
            }
            this.$refs['frm-materia-requisito'].show(); 

        },

        saveMateriaRequisito(){
            console.log(this.materiaRequisito);
            this.ss.storeRequisitos(this.materiaRequisito).then(
                (result) => {
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.getMateriaRequisito(this.materia.idMateria);
                    this.$refs['frm-materia-requisito'].hide(); 
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                }); 
        },

        saveMateria(){
            this.ss.storeMateriaCargaHoraria(this.materia).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                }
            ).catch(error => {
                console.log(error);
            });
        },

        destroyMateriaRequistio(item){
            this.ss.destroyRequisitos(item).then(
                (result) => {
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                    this.getMateriaRequisito(this.materia.idMateria);
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        cancelStoreMateriaRequisito(){
            this.materiaRequisito = {};
            this.$refs['frm-materia-requisito'].hide(); 

        },

        showMateriaCompetencia(id) {
                this.materiaCompetencia={};
                this.ss.showMateriaCompetencia(id).then(
                    (result) => {
                        let response = result.data;
                        if(response.data){
                            this.materiaCompetencia = response.data;
                            console.log(this.materiaCompetencia);
                            this.listMateriaCriterios(this.materiaCompetencia.id);
                        }
                        else{
                            this.materiaCompetencia = {};
                        }
                    }
                ).catch(error => {
                    console.log(error);
                    this.isLoading=false;
                });
        },

        listMateriaCriterios(id) {
            this.materiaCriterios = [];
            this.ss.listMateriaCriterios(id).then(
                (result) => {
                    let response = result.data;
                    if(response.data){
                        this.materiaCriterios = response.data;
                    }
                    else{
                        this.materiaCriterios = [];
                    }
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        listUnidadDidactica(id) {
            this.ss.listUnidadDidactica(id).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                    if(response.data){
                        this.unidadesDidacticas = response.data[0];
                        this.listContenidoAnalitico(this.unidadesDidacticas.id);
                    }
                    else{
                        this.unidadesDidacticas = null;
                    }
                    console.log(this.unidadesDidacticas);
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        listContenidoAnalitico(id) {
            this.ss.listContenidoAnalitico(id).then(
                (result) => {
                    let response = result.data;
                    if(response.data){
                        this.contenidoAnalitco = response.data;
                    }
                    else{
                        this.contenidoAnalitco = [];
                    }
                    console.log(this.contenidoAnalitco);
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        editPersona() {
            this.$refs['frm-persona'].show();
            this.$refs['view-persona'].hide();
        },
        cancelJustificacion() {
            this.justificacion = {};
            this.$refs['frm-justificacion'].hide();
        },
        cancelMateriaCompetencia() {
            this.competencia = {};
            this.$refs['frm-materia-competencia'].hide();
        },
        cancelContenidoAnalitico() {
            this.contenidoA = {};
            this.$refs['frm-contenido-analitico'].hide();
        },

        cancelMateriaPropuesta() {
            this.materiaPropuesta = {};
            this.isPropuesta = false;
            this.$refs['frm-marco-referencial'].hide();
        },

        saveJustificacion() {
            if(this.materia2.Justificacion){
                //let justificacionArreglado = this.materia.Justificacion.replace(/\n/g,' ');
                let justificacionArreglado = this.materia2.Justificacion;
                this.materia2.Justificacion = justificacionArreglado;
            }
            console.log(this.materia2);
            this.ss.storeMateria(this.materia2).then(
                (result) => {
                    let response = result.data;
                    this.materia2 = response.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-justificacion'].hide();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },

        changeCompetencia(){
            console.log(this.materiaCompetenciaSelect);
            if(this.materiaCompetenciaSelect.CodigoInst){
                this.materiaCompetencia.CodigoCompetencia = this.materiaCompetenciaSelect.CodigoInst;
                this.materiaCompetencia.DescripcionCompetencia = this.materiaCompetenciaSelect.CompetenciaInstrumental;
            }else{
                this.materiaCompetencia.CodigoCompetencia = this.materiaCompetenciaSelect.CodigoEsp;
                this.materiaCompetencia.DescripcionCompetencia = this.materiaCompetenciaSelect.CompetenciaEspecifica;
            }
        },

        saveMateriaCompetencia() {
            
            this.materiaCompetencia.idMateria = this.materia.idMateria;
            console.log(this.materiaCompetencia);
            this.ss.storeMateriaCompetencia(this.materiaCompetencia).then(
                (result) => {
                    let response = result.data;
                    if(response.data){
                        this.materiaCompetencia = response.data;
                        console.log(this.materiaCompetencia);
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Correcto',
                                variant: 'success',
                                autoHideDelay: 5000
                            }
                        )
                    }    
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });  
        },

        saveMateriaCriterioDesempenio() {
            this.criterio.MateriaCompetencia_id = this.materiaCompetencia.id;
            let criterioArreglado = this.criterio.DescripcionCriterio.replace(/\n/g, ' ');
            this.criterio.DescripcionCriterio = criterioArreglado;

            console.log(this.criterio);
            this.ss.storeMateriaCriterios(this.criterio).then(
                (result) => {
                    let response = result.data;
                    console.log(response.data);
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.criterio= {};
                    this.listMateriaCriterios(this.materia.idMateria);
                    this.$refs['frm-criterio-desempenio'].hide();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                }); 
        },

        saveMateriaPropuesta() {
            console.log(this.materiaPropuesta);
        },

        saveMateriaUnidadDidactica() {
            this.unidadDidactica.MateriaCriterioDesempeno_id = this.criterioSeleccionado.id;
            let unidad = this.unidadDidactica.UnidadDidactica.replace(/\n/g, ' ');
            this.unidadDidactica.UnidadDidactica = unidad;
            console.log(this.unidadDidactica);
            this.ss.storeUnidadDidactica(this.unidadDidactica).then(
                (result) => {
                    let response = result.data;
                    console.log(response.data);
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.listUnidadDidactica(this.criterioSeleccionado.id);
                    console.log(response);
                    this.$refs['frm-unidad-didactica'].hide();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },

        async saveContenidoAnalitico() {
            await this.listUnidadDidactica(this.criterioSeleccionado.id);

            let contenidoAnaliticoSave={};

            if(this.contenidoA.ContenidoAnalitico.includes('//')){

                let contenidos = this.contenidoA.ContenidoAnalitico.split('\n');

            for (let index = 0; index < contenidos.length; index++) {
                if(contenidos[index].includes('//')){
                    var conteo = (contenidos[index].match(/\/\//g) || []).length;

                    if (conteo > 1) {
                        alert('Más de una ocurrencia de "//" detectada');
                        return;
                    }else{
                        continue;
                    }
                }else{
                    if(contenidos[index] === ""){
                        contenidos.splice(index, 1);
                    }else{
                        alert('debe incluir los separadores "//" entre el codigo y el contenido analitico.');
                        return;
                    }
                }
            }



            for (let index = 0; index < contenidos.length; index++) {
                
                let separado = contenidos[index].split('//');
                contenidoAnaliticoSave.Nivel = separado[0];
                contenidoAnaliticoSave.ContenidoAnalitico = separado[1];
                contenidoAnaliticoSave.MateriaContenido_id = this.unidadesDidacticas.id;

                await new Promise(resolve => setTimeout(resolve, 500));

                this.ss.storeContenidoAnalitico(contenidoAnaliticoSave).then(
                    (result) => {
                        let response = result.data;
                        console.log(response.data);
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Correcto',
                                variant: 'success',
                                autoHideDelay: 5000
                            }
                        )
                    })
                    .catch((error) => {
                        this.errorBag = error.response.data.errors;
                        this.$bvToast.toast(
                            'Problema al Guardar el Registro, favor verificar los Datos',
                            {
                                title: 'Error',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    });

            }

            this.listUnidadDidactica(this.criterioSeleccionado.id);
            this.$refs['frm-contenido-analitico'].hide();

            }else{
                alert('debe incluir los separadores "//" entre el codigo y el contenido analitico.');
            }
        },

        deleteCriterioDesempenio(item) {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyMateriaCriterios(item)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.listMateriaCriterios(this.materia.idMateria);
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },

        deleteUnidadDidactica(item) {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyUnidadDidactica(item)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.listUnidadDidactica(this.criterioSeleccionado.id);
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },

        deleteContenidoAnalitico(item) {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyContenidoAnalitico(item)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.listUnidadDidactica(this.criterioSeleccionado.id);
                            })
                            .catch(error => {
                                console.log(error);
                            }) 
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },

        buscarSagaPensum() {

            console.log(this.buscarSaga);

            let Materia = this.buscarSaga.Materia;
            let Sigla = this.buscarSaga.Sigla;
            let Especialidad = this.buscarSaga.Especialidad;
            let Gestion = this.buscarSaga.Gestion;
            let idUnidadAcademica = 1;
            if(this.buscarSaga.UnidadAcademica){
                idUnidadAcademica = this.buscarSaga.UnidadAcademica - 1;
            }

            this.ajax.data = function(d){
                d.Materia = Materia;
                d.Sigla = Sigla;
                d.Especialidad = Especialidad
                d.Gestion = Gestion;
                d.idUnidadAcademica = idUnidadAcademica;
            }

            this.$refs['datatable-pensums'].reloadAjax(this.ajax);

            this.ss.getPensums(this.buscarSaga).then(
                (result) => {
                    let response = result.data;
                    this.pensums = response.data;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });

        },

        showPensum() {
            this.$refs['view-pensum'].show();
        },

        showUnidadDidactica() {
            this.$refs['frm-Unidad-Didactica-Contenido-Analitico'].show();
        },

        cancelShowPensum() {
            this.$refs['view-pensum'].hide();
        },

        cancelStoreCriterio() {
            this.criterio = {};
            this.$refs['frm-criterio-desempenio'].hide();
        },

        cancelStoreUnidadDidactica() {
            this.unidadDidactica = {};
            this.$refs['frm-unidad-didactica'].hide();
        },
        editMarcoReferencial() {
            this.$refs['frm-marco-referencial'].show();
        },

        changeSeccion(value) {
            this.seccionValue = value;
            this.materiaCompetencia={};
            this.criterioSeleccionado = null;
            this.unidadesDidacticas=null;

            if (this.seccionValue == 1) {
                console.log('competencia');
                this.showMateriaCompetencia(this.materia.idMateria);
            }

            if (this.seccionValue == 2) {
                console.log('criterios');
                this.showMateriaCompetencia(this.materia.idMateria);
                this.listMateriaCriterios(this.materia.idMateria);
            }

            if (this.seccionValue == 3) {
                console.log('contenido');
                this.listMateriaCriterios(this.materia.idMateria);
            }

        },

        criterioSeleccion(item){
            this.criterioSeleccionado = item;
            this.contenidoAnalitco=[];
            this.listUnidadDidactica(this.criterioSeleccionado.id);
        },

        storeMateriaPropuesta(){
            this.isPropuesta=true;
            this.materiaPropuesta={};
            this.materiaPropuesta = { ...this.materia };
        },

        showCompetenciaGeneral(id){
            this.isLoading = true;
            this.ss.showCompetenciaGeneral(id).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                    if(response.success){
                        this.competenciaGeneral = response.data[0];
                        console.log(this.competenciaGeneral);
                        this.showCompetenciasInstrumentales(this.competenciaGeneral.id);
                        this.getCompetenciasEspecificas(this.pensum.idEspecialidad)

                    }
                    else{
                        this.competenciaGeneral={};
                        this.competenciasArea={};
                        this.competenciasInstrumentales=[];
                        this.showEspecialidad(id); 
                    }
                    this.isLoading = false;
                    this.$refs['view-especialidad'].show();
                }
            ).catch(error => {
                console.log(error);
            });
        },

        showCompetenciasInstrumentales(id){
            this.isLoading = true;
            this.ss.showCompetenciaInstrumentales(id).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                    if(response.success){
                        this.competenciasInstrumentales = response.data;
                        console.log(this.competenciasInstrumentales);
                    }
                    else{
                        this.competenciasInstrumentales=[];
                    }
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
            });
        },


        getEspecialidades(){
            this.ss.listEspecialidad().then(
               (result) => {
                   let response = result.data;
                   console.log(response);
                   this.especialidadesSAGA = response;
               }
           ).catch(error => {
               console.log(error);
           });
        },

        showReporte(){
                let idMateria = this.materia.id;
                console.log(this.materia);
                let url = `${process.env.VUE_APP_MAIN_SERVICE}api/Materia/pdfContenido?id=${idMateria}`;
                console.log(url);
                window.open(url, '_blank');
        },

        showReporteWord(){
            let idMateria = this.materia.idMateria
            let url = `${process.env.VUE_APP_MAIN_SERVICE}api/Materia/wordMateriaContenido?id=${idMateria}`;
            window.open(url);
        },

        
        draw() {
            window.$('.btn-datatable-Materia').on('click', (evt) => {
                let id = window.$(evt.target)[0].getAttribute('id');
                if(id){
                    this.isLoading=true;
                    this.showPensumMateria(id);
                    /*  */
                }else{
                    this.$bvToast.toast(
                        'Ocurrio un problema inesperado, intente nuevamente.',
                        {
                            title: 'Oops',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                }
            });

        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            this.persona = persona;
            
            this.getUnidadAcademica();
            this.getEspecialidad2();
            this.getEspecialidades();
        }
    }
};
