import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "PensumsPage",
    data() {
        let ss = new MainService();
        return {
            msg: "PensumsPage",
            ss: ss,
            ajax: {
                "url": ss.indexValidacion(),
                headers: ss.getToken(),
                data: function (d) {
                    d.idEspecialidad = 0;
                }
            },
            columns: [
                { data: 'idEspecialidad', name: 'idEspecialidad', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Especialidad', name: 'Especialidad', title: 'Especialidad' },
                { data: 'NivelAcad', name: 'NivelAcad', title: 'Nivel Académico' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            especialidad:{},
            usuario:{},
            competenciaProfesionalSelect:{},
            competenciaGeneral:{},
            competenciaArea:{},
            competenciaEspecifica:{},
            competenciaInstrumental:{},
            competenciasArea:[],
            competenciasEspecificas:[],
            competenciasInstrumentales:[],
            competenciaInstrumentalCCP:[],
            especialidadesSAGA:[],
            nombreEspecialidad:'',
            isLoading: false,
            isLoadingFile: false,
            isPropuesta: false,
            errorBag: {},
            password: {},
            observacion:{},
            observaciones:[],
            detalle:{},
            observacionDetalles:[],
            observacionDetalle:{},
            observacionSelect:{},
            validacion:{},
            validacionEstado:true,
            url:'',
            urlArchivo:'',
            urlPlanAsig:''
        };
    },
    methods: {
        
        loadFile(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            );
                            this.observacionSelect.urlObservacion = result.data.data;
                            this.observacionSelect.estadoObservacion = true;
                            console.log(this.observacionSelect);
                            this.isLoadingFile = false;
                            this.saveObservacion(this.observacionSelect)
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            ) 
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },

        loadFile2(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile2(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            );
                            this.validacion.urlValidacion = result.data.data;
                            this.isLoadingFile = false;
                            this.storeValidacion(this.validacion)
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            ) 
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },


        showEspecialidad(id){
            this.ss.showEspecialidad(id).then(
                (result) => {
                    let response = result.data;
                    this.especialidad = response[0];
                    console.log(this.especialidad);
                }
            ).catch(error => {
                console.log(error);
            });
        },

        async getEspecialidad2(){
            this.isLoading=true;
            console.log('promesa');
            await new Promise(resolve => setTimeout(resolve, 2000));

            if(this.usuario.idEspecialidad){
                let id = this.usuario.idEspecialidad;
                this.ajax.data = function(d){
                    d.idEspecialidad = id;
                }
                this.$refs['datatable-Especialidad'].reloadAjax(this.ajax);
            }
            this.isLoading=false;
        }, 

        getEspecialidades(){
            this.ss.listEspecialidad().then(
               (result) => {
                   let response = result.data;
                   console.log(response);
                   this.especialidadesSAGA = response;
               }
           ).catch(error => {
               console.log(error);
           });
        },

        showValidacion(id){
            
            this.isLoading = true;
            this.detalle={};
            this.detalle.idEspecialidad = id;
            this.detalle.gestion = 2024;
            this.ss.showValidacion(this.detalle).then(
                (result) => {
                    let response = result.data;
                    this.validacionEstado = response.success;
                    console.log(this.validacionEstado);
                    if(this.validacionEstado == false){
                        this.validacion = this.detalle;
                    }
                    else{
                        this.validacion = response.data;
                        this.urlArchivo = this.url + this.validacion.urlValidacion;
                        console.log(this.validacion);
                    }
                    this.isLoading = false;
                    this.$refs['view-validacion'].show();
                }
            ).catch(error => {
                console.log(error);
            });
            
        },

        storeValidacion(){
            this.validacion.estadoValidacion = true;
            this.ss.storeValidacion(this.validacion).then(
                (result) => {
                    let response = result.data;
                    this.validacion = response.data;
                    console.log(this.validacion);
                    this.urlArchivo = this.url + this.validacion.urlValidacion;
                    this.validacionEstado = true;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        generarActaValidacion(){
            let url = `${process.env.VUE_APP_MAIN_SERVICE}api/Validacion/ImprimirActaValidacoin?idEspecialidad=${this.validacion.idEspecialidad}&&idPersona=${this.usuario.id}`;
            console.log(url);
            window.open(url, '_blank');
        },

        showObservacion(id){
            this.isLoading = true;
            this.detalle.idEspecialidad = id;
            this.detalle.gestion = 2024;

            this.ss.listObservaciones(this.detalle).then(
                (result) => {
                    let response = result.data;
                    this.observaciones = response.data;
                    console.log(this.detalle);
                    this.isLoading = false;
                    this.$refs['view-formulariosHistoria'].show();
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getObservaciones(id){

            this.detalle={};
            this.detalle.idEspecialidad = id;
            this.detalle.gestion = 2024;
            this.ss.listObservaciones(this.detalle).then(
                (result) => {
                    let response = result.data;
                    this.observaciones = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getObservacionDetalle(item){
            this.observacionSelect = item;
            this.ss.listObservacionDetalle(item).then(
                (result) => {
                    let response = result.data;
                    this.observacionDetalles = response.data;
                    this.urlPlanAsig = response.url;
                    console.log(this.urlPlanAsig);
                    console.log(this.observacionDetalles);
                    this.$refs['view-observacion'].show();
                }
            ).catch(error => {
                console.log(error);
            });
        },

        showFormularioHistorial(){
            this.$refs['view-formulariosHistoria'].show();
        },


        storeObservacionDetalle(){
            this.observacionDetalle.idObservacion = this.observacionSelect.id;
            this.ss.storeObservacionDetalle(this.observacionDetalle).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                    this.observacionDetalle.Observacion = '';
                    this.observacionDetalle.Detalle = '';
                    this.getObservacionDetalle(this.observacionSelect);
                }
            ).catch(error => {
                console.log(error);
            });
        },

        destroyObservacionDetalle(item){
            this.ss.destroyObservacionDetalle(item).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                    this.getObservacionDetalle(this.observacionSelect);
                }
            ).catch(error => {
                console.log(error);
            });
        },

        saveObservacion(item){
            this.urlArchivo = this.url + item.urlObservacion;
            this.ss.storeObservacion(item).then(
                (result) => {
                    let response = result.data;
                    console.log(response.data);
                    this.getObservaciones(response.data.idEspecialidad);
                }
            ).catch(error => {
                console.log(error);
            });
        },

        agregarObservacion(){
            this.detalle.estadoObservacion = false;
            this.detalle.idunidadacademica = this.usuario.UnidadAcademica;
            console.log(this.detalle);
            this.saveObservacion(this.detalle);
        },

        eliminarObservacion(item){
            this.ss.destroyObservacion(item).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                    this.getObservaciones(response.data.idEspecialidad);
                }
            ).catch(error => {
                console.log(error);
            });
        },

        generarFormulario(){
            console.log(this.observacionSelect);
            let url = `${process.env.VUE_APP_MAIN_SERVICE}api/Validacion/ImprimirFormularioObservacion?id=${this.observacionSelect.id}&&idPersona=${this.usuario.id}`;
            console.log(url);
            window.open(url, '_blank');
        },



        cargarFormulario(item){
            this.observacionSelect=item;
            console.log('OBSERVACION SELECT');
            console.log(this.observacionSelect);
            this.urlArchivo = this.url + this.observacionSelect.urlObservacion;
            console.log(this.urlArchivo);
            this.$refs['view-FormularioObservacion'].show();
        },

        draw() {
            window.$('.btn-datatable-EspecialidadValidacion').on('click', (evt) => {
                let idEspecialidad = window.$(evt.target)[0].getAttribute('idEspecialidad');
                this.detalle={};
                if(idEspecialidad){
                    this.showValidacion(idEspecialidad);
                }else{
                    this.$bvToast.toast(
                        'Ocurrio un problema inesperado, intente nuevamente.',
                        {
                            title: 'Oops',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                }
            });

            window.$('.btn-datatable-EspecialidadObservacion').on('click', (evt) => {
                let idEspecialidad = window.$(evt.target)[0].getAttribute('idEspecialidad');
                this.detalle={};
                this.detalle.idEspecialidad = idEspecialidad;
                if(idEspecialidad){
                    this.showObservacion(this.detalle.idEspecialidad );
                }else{
                    this.$bvToast.toast(
                        'Ocurrio un problema inesperado, intente nuevamente.',
                        {
                            title: 'Oops',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                }
            });

        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            this.usuario = persona;
            console.log('logeado');
            this.getEspecialidad2();
            this.getEspecialidades();
            this.url = process.env.VUE_APP_MAIN_SERVICE + 'storage/documents/';
            console.log(this.url);
        }
    }
};
