import { render, staticRenderFns } from "./ValidacionPage.vue?vue&type=template&id=f31e6266"
import script from "./ValidacionPage.js?vue&type=script&lang=js&external"
export * from "./ValidacionPage.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports