import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "PensumsPage",
    data() {
        let ss = new MainService();
        return {
            msg: "PensumsPage",
            ss: ss,
            ajax: {
                "url": ss.indexMateria(),
                headers: ss.getToken(),
                data: function (d) {
                    d.idEspecialidad = 0;
                }
            },
            columns: [
                { data: 'idMateria', name: 'idMateria', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Materia', name: 'Materia', title: 'Materia' },
                { data: 'Sigla', name: 'Sigla', title: 'Sigla' },
                { data: 'TipoMateria', name: 'TipoMateria', title: 'Tipo Materia' },
                { data: 'tipo', name: 'tipo', title: 'tipo' },
                //{ data: 'Periodo', name: 'Periodo', title: 'Periodo' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            seccionValue: 0,
            onclickCompetencia: 0,
            onclickCriterios: 0,
            onclickContenidos: 0,
            idMateria: 0,
            rols: [],
            cargasHorarias: [],
            cargaH:{},
            especialidadsaga:{},
            especialidadesSAGA:[],
            cargaHorariaTeoria: {},
            cargaHorariaLaboratorio: {},
            cargaHoraria: {},
            persona: {},
            competencia: {},
            creditos: 0,
            isLoading: false,
            isLoadingFile: false,
            isPropuesta: false,
            errorBag: {},
            password: {},

            materia: {},
            materia2: {}
        };
    },

    computed: {
        totalHoras() {
            return this.cargasHorarias.reduce((sum, ch) => sum + (parseInt(ch.Horas) || 0), 0);
        },

    },

    methods: {
        loadFile(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.persona.Foto = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },
        getPensums() {
            this.ss.listPensums().then(
                (result) => {
                    let response = result.data;
                    let data = response.data;
                    this.pensums = data;
                    console.log(this.pensums);
                }
            ).catch(error => {
                console.log(error);
            });
        },



        

        showCargaHorariaView() {
            this.$refs['view-carga-horaria'].show();
        },

        cancelCargaHorariaView() {
            this.$refs['view-carga-horaria'].hide();
        },

        storeCargaHoraria(item){
            this.cargaH = item;
            this.$refs['frm-carga-horaria'].show();
        },
        storeCreditos(){
            this.cargaH.idMateria = this.materia.idMateria;
            this.cargaH.idEspecialidad = this.materia.idEspecialidad;
            this.cargaH.Gestion = this.materia.Gestion;
            this.$refs['frm-creditos'].show();
        },
        cancelStoreCreditos(){
            this.cargaH = {};
            this.$refs['frm-creditos'].hide();
        },
        cancelStoreCargaHoraria(){
            this.cargaH = {};
            this.$refs['frm-carga-horaria'].hide();
        },
        saveCargaHorariaT(){

            if(!this.cargaH.idMateria){
                this.cargaH.idMateria = this.materia.idMateria;
                this.cargaH.idEspecialidad = this.materia.idEspecialidad;
                this.cargaH.Gestion = this.materia.Gestion;
                this.cargaH.accion = 0;

            }else{
                this.cargaH.accion = 1;
            }

            this.ss.storeMateriaCargaHoraria(this.cargaH).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                }
            ).catch(error => {
                console.log(error);
            });

            this.showCargaHoraria(this.materia);
            this.cargaH = {};
            this.$refs['frm-carga-horaria'].hide();

        },

        saveCreditosMateria(){


            if(Array.isArray(this.cargasHorarias) && this.cargasHorarias.length > 0){

                for (let index = 0; index < this.cargasHorarias.length; index++) {
                    console.log(this.cargasHorarias[index]);
                    this.cargasHorarias[index].Creditos = this.cargaH.Creditos;
                    this.ss.storeMateriaCargaHoraria(this.cargasHorarias[index]).then(
                        (result) => {
                            let response = result.data;
                            console.log(response);
                            
                        }
                    ).catch(error => {
                        console.log(error);
                    });
                }

                this.showCargaHoraria(this.materia);
                this.cargaH = {};
                this.$refs['frm-creditos'].hide();
            }

        },

        showPensumMateria(id){
            this.materia={};
            this.materia2={};
            this.ss.showPensum(id).then(
                (result) => {
                    let response = result.data;
                    this.materia = response.data;
                    //this.materia2 = this.materia;
                    console.log("Metodo correcto");
                    console.log(this.materia);
                    this.showCargaHoraria(this.materia);
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },



        showCargaHoraria(id){
            this.isLoading=true;
            this.cargasHorarias={};
            this.ss.showMateriaCargaHoraria(id).then(
                (result) => {
                    let response = result.data;
                    console.log("carga horaria",response);
                    this.cargasHorarias = response.data;
                    console.log(this.cargasHorarias);
                    this.showCargaHorariaView();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        async getEspecialidad2(){
            this.isLoading=true;
        if(this.especialidadsaga.idEspecialidad){
            let id = this.especialidadsaga.idEspecialidad;
            this.ajax.data = function(d){
                d.idEspecialidad = id;
            }
        }
        else{
            let id = this.persona.idEspecialidad;
            this.ajax.data = function(d){
                d.idEspecialidad = id;
            }
        }
        await new Promise(resolve => setTimeout(resolve, 2000));
    
        this.$refs['datatable-Materia'].reloadAjax(this.ajax);
        this.isLoading=false;
        }, 

        getEspecialidades(){
            this.ss.listEspecialidad().then(
               (result) => {
                   let response = result.data;
                   console.log(response);
                   this.especialidadesSAGA = response;
               }
           ).catch(error => {
               console.log(error);
           });
        },

        showReporte(){

            if(this.persona.idEspecialidad){
                let idEspecialidad = this.persona.idEspecialidad;
                let url = `${process.env.VUE_APP_MAIN_SERVICE}api/Materia/pdfCargaHoraria?id=${idEspecialidad}`;
                console.log(url);
                window.open(url, '_blank');
                this.persona.idEspecialidad=null;
                this.$refs['view-especialidad-select'].hide();
            }
            else{
                this.$refs['view-especialidad-select'].show();
            }
        },

        draw() {
            window.$('.btn-datatable-Materia').on('click', (evt) => {
                let id = window.$(evt.target)[0].getAttribute('id');
                if(id){
                    this.id = id;
                    this.cargasHorarias= [];
                    this.showPensumMateria(id);
                }else{
                    this.$bvToast.toast(
                        'Ocurrio un problema inesperado, intente nuevamente.',
                        {
                            title: 'Oops',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                }
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            this.persona = persona;
            this.getEspecialidad2();
            this.getEspecialidades();
        }
    }
};
